export const LOADING = 'LOADING';

export const ALL_CLIENT_BALANCE = 'ALL_CLIENT_BALANCE';

export const ALL_CLIENT_PAYMENT = 'ALL_CLIENT_PAYMENT';

export const GET_SINGLE_CLIENT_BALANCE = 'GET_SINGLE_CLIENT_BALANCE';
export const GET_SINGLE_CLIENT_PAYMENT = 'GET_SINGLE_CLIENT_PAYMENT';

export const GET_CLIENT_BALANCE_BY_PROJECTID = 'GET_CLIENT_BALANCE_BY_PROJECTID';
export const GET_CLIENT_BALANCE_BY_CLIENTID = 'GET_CLIENT_BALANCE_BY_CLIENTID';

export const GET_CLIENT_PAYMENT_BY_PROJECTID = 'GET_CLIENT_PAYMENT_BY_PROJECTID';
export const GET_CLIENT_PAYMENT_BY_CLIENTID = 'GET_CLIENT_PAYMENT_BY_CLIENTID';

export const GET_TOTAL_STATUS_CLIENT_BALANCE = 'GET_TOTAL_STATUS_CLIENT_BALANCE';

export const GET_SINGLE_STATUS_CLIENT_BALANCE = 'GET_SINGLE_STATUS_CLIENT_BALANCE';

export const CREATE_CLIENT_BALANCE = 'CREATE_CLIENT_BALANCE';
export const CREATE_CLIENT_PAYMENT = 'CREATE_CLIENT_PAYMENT';

export const UPDATE_CLIENT_BALANCE = 'UPDATE_CLIENT_BALANCE';
export const UPDATE_CLIENT_PAYMENT = 'UPDATE_CLIENT_PAYMENT';

export const DELETE_CLIENT_BALANCE = 'DELETE_CLIENT_BALANCE';
export const DELETE_CLIENT_PAYMENT = 'DELETE_CLIENT_PAYMENT';
